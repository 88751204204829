<form #passwordForm="ngForm" (ngSubmit)="submitPassword(passwordForm)" class="form-horizontal" novalidate>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Cancel" title="Cancel" (click)="cancelModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Provide password</h4>
  </div>

  <div class="modal-body">
    <div>
      User will be notified via email about the new password.
      <span *ngIf="userDraft.locked?.typeId === 2"
        >They will be able to use that password before 11:59 pm EST tonight.</span
      >
    </div>

    <div class="form-group row standard-margin-top">
      <div class="col-xs-12">
        <div class="form-group" [ngClass]="{ 'has-error': false }" *ngIf="userDraft">
          <label for="password" class="col-sm-4 control-label">New password <required-field></required-field></label>
          <div class="col-sm-8">
            <password-input
              [(ngModel)]="userDraft.password"
              name="password"
              #passwordControl="ngModel"
              [user]="userDraft"
              [submitted]="submitted"></password-input>
          </div>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error':
              userDraft.password !== passwordConfirmationValue && userDraft.password && passwordConfirmation.dirty,
          }">
          <label for="passwordConfirmation" class="col-sm-4 control-label">
            Confirm new
            <span class="nowrap">password <required-field class="display-inline-block"></required-field></span>
          </label>
          <div class="col-sm-8">
            <input
              #passwordConfirmation="ngModel"
              [(ngModel)]="passwordConfirmationValue"
              name="passwordConfirmation"
              class="form-control required"
              type="password"
              id="passwordConfirmation"
              autocomplete="off"
              placeholder="Confirm New Password"
              required
              [passwordMatch]="userDraft.password" />
            <p class="help-block" *ngIf="passwordConfirmation.invalid && (passwordConfirmation.dirty || submitted)">
              <span *ngIf="passwordConfirmation.errors?.passwordMismatch"> Passwords do not match. </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="error" class="alert alert-sm alert-danger">{{ error }}</div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-warning"
      (click)="cancelModal()"
      title="Cancel"
      [disabled]="changePasswordSubscriber">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="passwordForm.invalid || changePasswordSubscriber"
      title="Submit">
      Submit
    </button>
  </div>
</form>
