<ng-template #separator>
  <hr aria-hidden="true" class="tw-m-0 tw-flex-1 tw-border tw-border-gray-4" />
</ng-template>

<div class="tw-flex tw-flex-col tw-gap-4">
  <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
    <button
      type="button"
      class="tw-rounded-lg tw-border tw-border-brand-7 tw-bg-brand-2 tw-px-4 tw-py-3 tw-text-brand-9 hover:tw-border-brand-8 hover:tw-bg-brand-3 focus:tw-outline-none focus-visible:tw-outline-brand-7"
      *ngFor="let item of shownItems"
      (click)="called(item)">
      {{ item.name }}
    </button>
  </div>
  <div class="tw-flex tw-items-center tw-gap-4" *ngIf="couldDecrease() || couldExpand()">
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <button
      type="button"
      class="twc-btn twc-btn-link"
      (click)="toggleExpand($event)"
      [innerText]="couldExpand() ? 'Show more' : 'Show less'"></button>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  </div>
</div>
