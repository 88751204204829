<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Email verification importance</h4>
</div>

<div class="modal-body">
  <h4 class="mt-0">Identity confirmation</h4>
  <p>Email verification confirms that the person signing up is the legitimate owner of the email account.</p>

  <h4 class="mt-0">Security</h4>
  <p>
    Email verification adds an extra layer of security by confirming that the email provided is valid and can be used
    for communication and account recovery.
  </p>

  <h4 class="mt-0">Fraud and spam protection</h4>
  <p>Email verification helps to prevent fraudulent and spam emails from reaching your inbox.</p>
</div>
