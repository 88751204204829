<div role="main">
  <router-outlet>
    <div>
      <h3 class="mt-0 font-24px">{{ signInWording.SignIn || 'Sign in' }}</h3>
      <div class="mt-20 mb-15" *ngIf="settings.login.becomeCourseProvider">
        Unlock a world of public health training resources by logging into {{ affiliateShotName }}.
      </div>
    </div>
    <div>
      <div *ngIf="currentSiteSetting?.integrationLoginPath">
        <button
          type="button"
          class="btn btn-default btn-block btn-lg integration-login-button"
          (click)="showCorporateLogin()"
          [disabled]="loading">
          <span class="integration-login"></span>
          <span class="integration-login-default fa-brands fa-google"></span>
          <span class="integration-login-text">
            <span *ngIf="!settings.login.corporateLoginInvitation"
              >{{ signInWording.SignIn || 'Sign in' }} using corporate account</span
            >
            <span *ngIf="settings.login.corporateLoginInvitation">{{ settings.login.corporateLoginInvitation }}</span>
          </span>
        </button>

        <hr class="hr-or-text text-muted mt-25" data-content="OR" />
      </div>

      <password-login
        [showLabels]="true"
        [showResetPassword]="true"
        [email]="email"
        [nextTargetUrl]="next"></password-login>

      <div class="form-group standard-margin-top" *ngIf="settings.login.disclaimer">
        <hr />
        <div class="login-info" [innerHTML]="settings.login.disclaimer"></div>
      </div>

      <div class="enroll" *ngIf="isStateAvailable()">
        <hr class="hr-or-text" data-content="OR" />

        <button
          type="button"
          class="btn btn-lg btn-block"
          [ngClass]="{
            'btn-default': settings.login.createAccountStyle === 'default',
            'btn-primary btn-outline': settings.login.createAccountStyle === 'primary',
          }"
          [disabled]="loading"
          (click)="createAccount()"
          title="Create new account">
          Create new account
        </button>
      </div>
      <div
        class="text-center mt-15 text-center"
        id="becomeCourseProvider"
        *ngIf="isStateAvailable() && settings.login.becomeCourseProvider && !hideBecomeCourseProvider">
        <h5 class="mt-0 mb-5">Want to add courses to TRAIN?</h5>
        <button
          type="button"
          class="btn btn-link no-padding-all"
          title="Become a course provider"
          ng-disabled="loginCtrl.loading"
          (click)="createAccount(true)">
          Become a course provider
        </button>
      </div>
    </div>
  </router-outlet>
</div>
