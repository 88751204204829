import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CorporateLoginComponent } from 'modules/login/components/corporate-login.component';
import { EmailVerificationRequiredAlertDialogComponent } from 'modules/login/components/email-verification-required-alert.component';
import { ForgotPasswordComponent } from 'modules/login/components/forgot-password.component';
import { LoginFormComponent } from 'modules/login/components/login-form.component';
import { EmailVerificationImportantModalComponent } from 'modules/login/components/modals/email-verification-important-modal.component';
import { PasswordAssistanceComponent } from 'modules/login/components/password-assistance.component';
import { PasswordLoginComponent } from 'modules/login/components/password-login.component';
import { loginRoutingModule } from 'modules/login/login-routing.module';
import { UserVerificationService } from 'modules/login/services/user-verification.service';
import { NavigationModule } from 'modules/navigation/navigation.module';
import { PasswordModule } from 'modules/password/password.module';
import { UserPasswordService } from 'modules/password/services/user-password.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NavigationModule,
    FormsModule,
    UIRouterUpgradeModule.forChild({ states: loginRoutingModule }),
    RouterOutlet,
    LmsComponentsModule,
    PasswordModule,
  ],
  providers: [UserPasswordService, UserVerificationService],
  declarations: [
    CorporateLoginComponent,
    LoginFormComponent,
    PasswordLoginComponent,
    ForgotPasswordComponent,
    PasswordAssistanceComponent,
    EmailVerificationRequiredAlertDialogComponent,
    EmailVerificationImportantModalComponent,
  ],
  exports: [PasswordLoginComponent],
})
export class LoginModule {}
