<div *ngIf="filteredCustomRules.length > 0" class="has-error">
  <div class="help-block" *ngFor="let passwordRule of filteredCustomRules">
    <ng-container *ngIf="passwordRule.isValid === false">
      <span *ngIf="!passwordRule.rule.requiresValue || !passwordRule.ruleData">
        {{ passwordRule.rule.description }}
      </span>
      <span *ngIf="passwordRule.rule.requiresValue && passwordRule.ruleData">
        {{ passwordRule.formattedDescription }}
      </span>
    </ng-container>
  </div>
</div>

<div *ngIf="filteredRules.length > 0" class="well well-sm sm-padding-all no-margin-bottom">
  <h5 class="no-margin-top">Password must:</h5>
  <ul class="list-unstyled no-margin-bottom">
    <li *ngFor="let passwordRule of filteredRules" style="margin-bottom: 2px">
      <div class="flex">
        <div class="flex-row">
          <div class="flex-col flex-col-1x sm-margin-right">
            <span
              class="fa-regular"
              [ngClass]="{
                'fa-circle': passwordRule.isValid === undefined && !loading,
                'fa-solid fa-circle-notch fa-spin': passwordRule.isValid === undefined && loading,
                'fa-check-circle text-success': passwordRule.isValid === true,
                'fa-times-circle text-danger': passwordRule.isValid === false,
              }"></span>
          </div>
          <div class="flex-col flex-col-grow">
            <div *ngIf="!passwordRule.rule.requiresValue || !passwordRule.ruleData">
              {{ passwordRule.rule.description }}
            </div>
            <div *ngIf="passwordRule.rule.requiresValue && passwordRule.ruleData">
              {{ passwordRule.formattedDescription }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
