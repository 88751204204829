import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { IVerificationResponse } from 'modules/login/models/verification.models';
import { Observable } from 'rxjs';

export enum VerificationEndpoints {
  VERIFICATION_INFO = '/a/user/:userId/email_verification/',
}

@Injectable()
export class UserVerificationService {
  constructor(private http: HttpClient) {}

  getVerificationInfo(userId: number): Observable<IVerificationResponse> {
    return this.http.get<IVerificationResponse>(
      ElmsUtils.formatUrl(VerificationEndpoints.VERIFICATION_INFO, { userId: userId }),
    );
  }
}
