<div class="d-flex gap-10 items-center mb-15">
  <div class="position-relative">
    <round-progress
      [max]="100"
      [current]="completeness"
      color="#3b81e9"
      background="#f2f2f2"
      [radius]="49"
      [stroke]="7"
      [semicircle]="false"
      [rounded]="true"
      [clockwise]="true"
      [responsive]="false"
      [duration]="800"
      animation="easeInOutQuart"></round-progress>
    <div class="h3 mt-0 mb-0" style="top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute">
      {{ completeness }}%
    </div>
  </div>
  <div class="text-muted">To get personalized recommendations please complete your community profile.</div>
</div>
<button
  type="button"
  [id]="trackingName + '_complete_profile_click'"
  title="Complete profile"
  class="btn btn-outline btn-block"
  (click)="editProfile()">
  Complete Profile
</button>
