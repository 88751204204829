<div *ngIf="currentUser?.anonymous">
  <form #loginForm="ngForm" method="POST" action="/a/login/" id="loginForm" (keyup.enter)="login(loginForm)" novalidate>
    <legend class="sr-only">{{ signInWording.SignIn }} Form</legend>

    <div class="alert alert-sm alert-danger" *ngIf="loginError?.length > 0">{{ loginError }}</div>

    <div class="form-group">
      <label [ngClass]="{ 'sr-only': !showLabels }" for="loginField" class="control-label">{{
        signInWording.Username
      }}</label>
      <input
        type="text"
        id="loginField"
        placeholder="{{ showLabels ? '' : signInWording.Username }}"
        class="form-control"
        [disabled]="stage === 'validation'"
        [(ngModel)]="credentials.username"
        name="username"
        required
        maxlength="255"
        autocomplete="off"
        autoFocus
        #username="ngModel" />
    </div>

    <div class="form-group d-flex flex-direction-column">
      <div style="order: 2">
        <input
          type="password"
          id="passwordField"
          placeholder="{{ showLabels ? '' : 'Password' }}"
          class="form-control"
          [disabled]="stage === 'validation'"
          [(ngModel)]="credentials.password"
          name="password"
          required
          maxlength="255"
          autocomplete="off"
          #password="ngModel" />
      </div>
      <div class="d-flex justify-content-between" style="order: 1">
        <label [ngClass]="{ 'sr-only': !showLabels }" for="passwordField" class="control-label">Password</label>
        <div style="font-weight: 500" *ngIf="showResetPassword">
          <a
            [href]="getForgotPasswordLink"
            title="Forgot {{ signInWording.username }} or password?"
            class="no-text-decoration"
            >Forgot password?</a
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        type="button"
        id="loginBtn"
        class="btn btn-lg btn-primary btn-block"
        [disabled]="stage === 'validation'"
        title="{{ signInWording.SignIn }}"
        (click)="login(loginForm)">
        {{ signInWording.SignIn }}
      </button>
    </div>

    <!-- Hidden submit and target path -->
    <input type="submit" hidden [disabled]="submitDisabled || stage === 'validation'" tabindex="-1" value="submit" />
    <input type="hidden" name="redirectTo" #redirectTo="ngModel" [(ngModel)]="targetPath" tabindex="-1" />
  </form>
</div>
