import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { SecurityService } from 'core/services';
import { IUserNetworkEntity } from 'modules/network/models/user-network-activity.model';
import { NetworkService } from 'modules/network/services/network.service';
import { UserNetworkCommentsService } from 'modules/network/services/user-network-comments.service';
import moment from 'moment';
import { Unsubscribable, finalize } from 'rxjs';

@Component({
  standalone: false,
  selector: 'network-activity-comment',
  templateUrl: './network-activity-comment.component.html',
  providers: [NetworkService],
})
export class NetworkActivityCommentComponent implements OnInit, OnDestroy {
  @Input() comment: IUserNetworkEntity;
  @Input() isEditable: boolean;
  @Input() activityOrigin: string;
  @Input() allowLikes: boolean;
  @Input() trackingName: string;
  @Output() commentDeleted = new EventEmitter<IUserNetworkEntity>();

  likePromise?: Unsubscribable;
  savePromise?: Unsubscribable;

  user = this.currentUser.get();

  editMode = false;
  isCommentEditable = false;
  selfView = false;
  mobileView = false;
  modalSubscriber?: Unsubscribable;

  readonly isStateAvailable = this.securityService.isStateAvailable;

  constructor(
    private globalConfig: GlobalConfig,
    private currentUser: CurrentUserService,
    private userCommentsService: UserNetworkCommentsService,
    private networkService: NetworkService<IUserNetworkEntity>,
    private securityService: SecurityService,
  ) {}

  ngOnInit() {
    this.selfView = this.comment.user && this.comment.user.id === this.user.id;
    const hasPermissions = this.user.checkPermission('network.comment.edit');

    this.isCommentEditable = this.isEditable || (this.globalConfig.settings.view === 'admin' && hasPermissions);

    this.networkService.init(this.userCommentsService);
  }

  ngOnDestroy() {
    if (this.modalSubscriber) {
      this.modalSubscriber.unsubscribe();
      delete this.modalSubscriber;
    }
  }

  isEdited(): boolean {
    return this.comment.date.toString() !== this.comment.updated.toString();
  }

  deleteComment() {
    if (this.savePromise) {
      return;
    }

    const draft = Object.assign({}, this.comment);

    this.savePromise = this.userCommentsService
      .delete(draft)
      .pipe(
        finalize(() => {
          this.savePromise.unsubscribe();
          delete this.savePromise;
        }),
      )
      .subscribe(() => {
        this.commentDeleted.next(this.comment);
      });
  }

  editComment() {
    this.modalSubscriber = this.networkService
      .editThreadReplyDialog(this.comment)
      .pipe(
        finalize(() => {
          this.modalSubscriber.unsubscribe();
          delete this.modalSubscriber;
        }),
      )
      .subscribe((comment) => {
        Object.assign(this.comment, comment);
      });
  }

  formatDuration(): string {
    return moment(this.comment.date).fromNow();
  }

  toggleLike() {
    this.savePromise = this.networkService
      .toggleLike(this.comment)
      .pipe(
        finalize(() => {
          this.savePromise.unsubscribe();
          delete this.savePromise;
        }),
      )
      .subscribe();
  }
}
