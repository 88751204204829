import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { IRecoveryResponse, IResultResponse, ITokenResponse } from 'modules/login/models/reset-pasword.models';
import { ProvidePasswordModalComponent } from 'modules/password/components/modal/provide-password-dialog.component';
import { ResetPasswordModalComponent } from 'modules/password/components/modal/reset-password-dialog.component';
import { IUser } from 'modules/user/models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum SecurityEndpoints {
  passwordRecovery = '/a/user/security/recover_password/',
  tokenResult = '/a/user/security/assistance/:token/',
  passwordReset = '/a/user/security/:userId/reset_password/',
  updatePassword = '/a/user/:userId/update_password/',
}

@Injectable()
export class UserPasswordService {
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
  ) {}

  resetPasswordDialog(user: IUser) {
    const modalRef = this.modalService.open(ResetPasswordModalComponent, { size: 'lg' });

    modalRef.componentInstance.user = user;

    return modalRef.result;
  }

  providePasswordDialog(user: IUser) {
    const modalRef = this.modalService.open(ProvidePasswordModalComponent, { size: 'lg' });

    modalRef.componentInstance.user = user;

    return modalRef.result;
  }

  resetPassword(userId: number, throwHttpErrors = false) {
    return this.http.get(ElmsUtils.formatUrl(SecurityEndpoints.passwordReset, { userId: userId }), {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  recoveryPassword(email: string, throwHttpErrors = false): Observable<IRecoveryResponse> {
    return this.http
      .post(
        SecurityEndpoints.passwordRecovery,
        { email },
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
      )
      .pipe(map((res: IRecoveryResponse) => res));
  }

  changePassword(
    userId: number,
    password: string,
    loginName: string,
    throwHttpErrors = false,
  ): Observable<IResultResponse> {
    return this.http
      .post(
        ElmsUtils.formatUrl(SecurityEndpoints.updatePassword, { userId: userId }),
        { password: password, loginName: loginName },
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
      )
      .pipe(map((res: IResultResponse) => res));
  }

  getTokenResult(token: string): Observable<ITokenResponse> {
    return this.http
      .get(ElmsUtils.formatUrl(SecurityEndpoints.tokenResult, { token: token }))
      .pipe(map((res: ITokenResponse) => res));
  }

  changePasswordByToken(token: string, password: string, throwHttpErrors = false): Observable<IResultResponse> {
    return this.http
      .post(
        ElmsUtils.formatUrl(SecurityEndpoints.tokenResult, { token: token }),
        { password },
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
      )
      .pipe(map((res: IResultResponse) => res));
  }
}
