import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { ProvidePasswordModalComponent } from 'modules/password/components/modal/provide-password-dialog.component';
import { ResetPasswordModalComponent } from 'modules/password/components/modal/reset-password-dialog.component';
import { PasswordInputComponent } from 'modules/password/components/password-input.component';
import { PasswordMatchDirective } from 'modules/password/components/password-match.directive';
import { PasswordPoliciesComponent } from 'modules/password/components/password-policies.component';
import { PasswordPoliciesService } from 'modules/password/services/password-policies.service';

@NgModule({
  imports: [CommonModule, FormsModule, LmsComponentsModule],
  providers: [PasswordPoliciesService],
  declarations: [
    PasswordMatchDirective,
    PasswordInputComponent,
    PasswordPoliciesComponent,
    ResetPasswordModalComponent,
    ProvidePasswordModalComponent,
  ],
  exports: [PasswordInputComponent, PasswordMatchDirective, ResetPasswordModalComponent, ProvidePasswordModalComponent],
})
export class PasswordModule {}
