<section class="thread-panel">
  <!-- Post START -->
  <div class="flex">
    <div class="flex-row" [ngStyle]="activity.deleted && { 'align-items': 'baseline' }">
      <!-- Avatar START -->
      <div class="flex-col mr-15">
        <user-avatar [user]="activity.user" [isTransparent]="true" [size]="48" aria-hidden="true"></user-avatar>
      </div>
      <!-- Avatar END -->

      <div class="flex-col flex-col-grow">
        <!-- User name, Date, Likes START -->
        <div class="d-flex align-items-center-sm flex-direction-column-xs gap-5 mb-5">
          <span class="font-bold discussion-title">
            <a
              *ngIf="isStateAvailable('main.user_network') && activity.user.hasSharedProfile"
              [title]="'View ' + activity.user.firstName + '\'s profile'"
              [id]="trackingName + '_profile_link' + activity.user.id"
              uiSref="main.user_network"
              [uiParams]="{ userId: activity.user.id }"
              [innerHtml]="activity.user.firstName + ' ' + activity.user.lastName"
              >User name</a
            >
            <span
              role="heading"
              aria-level="5"
              *ngIf="!isStateAvailable('main.user_network') || !activity.user.hasSharedProfile"
              [innerHtml]="activity.user.firstName + ' ' + activity.user.lastName"></span>
          </span>
          <span class="dot-delimiter hidden-xs" aria-hidden="true"></span>
          <span>
            <span class="text-muted" [ngbTooltip]="tooltip" [placement]="['bottom', 'top']">{{
              timeSinceActivity()
            }}</span>
            <span class="text-muted" *ngIf="!activity.deleted && isEdited()">
              <span class="dot-delimiter" aria-hidden="true"></span>
              edited
            </span>
            <span *ngIf="activity.deleted">
              <span class="dot-delimiter" aria-hidden="true"></span>
              <span class="text-danger">thread deleted</span>
            </span>
            <span
              *ngIf="activity.likes?.count"
              class="btn btn-primary btn-xs xs-margin-left"
              style="width: fit-content; font-size: 73%">
              <span class="fa fa-thumbs-up" aria-hidden="true"></span>
              <span class="like-count">{{ activity.likes?.count || 0 }} <span class="sr-only"> likes</span></span>
            </span>
          </span>
        </div>

        <!-- Course activity system message header START -->
        <div *ngIf="isCourseActivity() && systemMessagesEnable && !activity.deleted">
          I've
          <span *ngIf="activity.activityType === 'course_review'"> made a course review: </span>
          <span *ngIf="activity.activityType === 'course_registration'"> registered for the course: </span>
          <span *ngIf="activity.activityType === 'course_completion'"> completed the course: </span>
          <span *ngIf="activity.activityType === 'course_post'"> post a course resource or comment: </span>
          <a
            uiSref="main.course"
            [uiParams]="{ id: activity.courseId || activity.objectData?.id }"
            *ngIf="activity.objectTitle"
            >{{ activity.objectTitle }}</a
          >
        </div>
        <!-- Course activity system message header END -->

        <!-- Blog activity system message header START -->
        <div *ngIf="activity.activityType === 'blog_post' && !activity.deleted">
          I've made a new blog entry:
          <a
            uiSref="main.blog"
            [uiParams]="{ id: activity.objectData?.id }"
            *ngIf="activity.objectTitle"
            [id]="trackingName + '_object_link' + activity.objectData?.id"
            >{{ activity.objectTitle }}</a
          >
        </div>
        <!-- Blog activity system message header END -->

        <!-- Body text START -->
        <div *ngIf="activity.text && !activity.deleted && !editMode">
          <p class="overflow-hidden no-margin-bottom" [innerHtml]="activity.text | markdownToHtml"></p>
        </div>
        <!-- Body text  END -->

        <div *ngIf="activity.activityType === 'course_recommendation' && !activity.deleted" class="mt-5">
          <ul class="list-unstyled d-flex gap-5 flex-direction-column">
            <li class="">
              <horizontal-course-tile-modern
                [course]="activity.course"
                mode="component"
                [smallView]="mobileView"
                [trackingName]="trackingName"></horizontal-course-tile-modern>
            </li>
          </ul>
        </div>

        <!-- Actions START -->
        <div class="mb-5 mt-5" [id]="'post_' + activity.id" *ngIf="!selfView && allowLikes && !activity.deleted">
          <button
            type="button"
            class="btn btn-link no-padding-all no-text-decoration"
            title="Like"
            [disabled]="savePromise"
            [id]="trackingName + '_like_button_' + activity.id"
            (click)="toggleLike()">
            <span *ngIf="!activity.likes?.liked">Like </span>
            <span *ngIf="activity.likes?.liked">Unlike</span>
          </button>
        </div>
        <!-- Actions END -->
      </div>

      <div class="flex-col text-right">
        <div
          ngbDropdown
          *ngIf="!activity.deleted && (selfView || isEditable)"
          [display]="'dynamic'"
          [placement]="['bottom-end', 'auto']">
          <a title="Actions" type="button" class="dot-menu-large action-icon" href="#" ngbDropdownToggle>
            <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
            <span class="sr-only">Open actions menu</span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <button ngbDropdownItem type="button" class="button" title="Edit" (click)="editThread()">Edit</button>
            <button
              ngbDropdownItem
              type="button"
              class="button"
              title="Delete"
              (clickConfirmation)="deleteThread()"
              clickConfirmationTitle="Delete thread?"
              clickConfirmationMessage="This will delete your thread and all replies to it.
                    This operation is not reversible."
              clickConfirmationButtonOk="Delete thread">
              <span class="text-danger"> Delete </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Post END -->

  <hr
    class="dotted-divider lg"
    *ngIf="activity.comments.items.length > 0 && !activity.deleted"
    role="separator"
    aria-hidden="true" />

  <!-- Comment list START -->
  <div *ngIf="!activity.deleted">
    <network-activity-comment
      *ngFor="let comment of orderBy(activity.comments.items, 'date')"
      [isEditable]="isEditable"
      [trackingName]="trackingName"
      [comment]="comment"
      class="display-block"
      [ngClass]="{ 'mb-15': !activity.deleted }"
      [allowLikes]="allowLikes"
      (commentDeleted)="commentDeleted($event)"></network-activity-comment>
    <div class="text-right" *ngIf="!activity.deleted">
      <button
        type="button"
        class="btn btn-outline"
        title="Reply to thread"
        (click)="createReply()"
        [scrollTo]="'post_' + activity.id"
        [id]="trackingName + '_reply_button2_' + activity.id">
        Reply to thread
      </button>
    </div>
  </div>
  <!-- Comment list END -->
</section>

<ng-template #tooltip>
  <date-time [date]="activity.date"></date-time>
</ng-template>
