<ul class="nav-menu list-unstyled d-flex gap-10 mb-0 items-center">
  <li *ngFor="let tab of mainMenu">
    <a
      href=""
      [uiSref]="tab.state"
      class="nav-menu-item modern"
      [attr.title]="'Open ' + (tab.title | lowercase) + ' menu item'"
      *ngIf="tab.state"
      [uiParams]="tab.stateParams"
      [uiOptions]="{ inherit: false }"
      (click)="onMenuClick()"
      >{{ tab.title }}<span class="{{ tab.icon }} position-relative ml-5" ng-if="tab.icon"></span>
    </a>

    <a
      href="{{ tab.link }}"
      class="nav-menu-item modern"
      [attr.title]="'Open ' + (tab.title | lowercase) + ' menu item'"
      *ngIf="!tab.state"
      target="{{ tab.target }}"
      (click)="onMenuClick()"
      >{{ tab.title }}<span class="{{ tab.icon }} position-relative ml-5" ng-if="tab.icon"></span>
    </a>
  </li>
  <li ngbDropdown *ngIf="extMenu.length" #extenderDropdown="ngbDropdown">
    <!--more-->
    <a title="Menu" href="#" class="dropdown-toggle nav-menu-item modern" ngbDropdownToggle>
      {{ extenderTitle
      }}<span
        class="fa-solid ml-5 text-muted position-relative"
        [ngClass]="{ 'fa-angle-up': extenderDropdown.isOpen(), 'fa-angle-down': !extenderDropdown.isOpen() }"></span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-header-more">
      <ng-container *ngFor="let extTab of extMenu">
        <a
          href=""
          ngbDropdownItem
          [uiSref]="extTab.state"
          [uiParams]="extTab.stateParams"
          [uiOptions]="{ inherit: false }"
          *ngIf="extTab.state"
          (click)="onMenuClick()"
          [attr.title]="'Open ' + (extTab.title | lowercase) + ' menu item'">
          {{ extTab.title }}<span class="{{ extTab.icon }} position-relative ml-5" ng-if="extTab.icon"></span>
        </a>

        <a
          href="{{ extTab.link }}"
          ngbDropdownItem
          [attr.title]="'Open ' + (extTab.title | lowercase) + ' menu item'"
          *ngIf="!extTab.state"
          target="{{ extTab.target }}"
          (click)="onMenuClick()">
          {{ extTab.title }}<span class="{{ extTab.icon }} position-relative ml-5" ng-if="extTab.icon"></span>
        </a>
      </ng-container>
    </div>
  </li>
</ul>
