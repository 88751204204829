<form
  ngForm
  name="profileCompletenessForm"
  class="profile-completeness-modal"
  *ngIf="!loading"
  #profileCompletenessForm="ngForm"
  (ngSubmit)="submit(profileCompletenessForm.form)">
  <div class="modal-header">
    <h4 class="modal-title">Edit Community Profile</h4>
  </div>

  <div class="modal-body modal-body-overflow">
    <fieldset [disabled]="processing" class="form-horizontal">
      <legend class="sr-only">Complete profile</legend>
      <div
        class="form-group row"
        *ngIf="checkField('firstName')"
        [ngClass]="{ 'has-error': firstName.invalid && (profileCompletenessForm.submitted || firstName.dirty) }">
        <label for="firstName" class="col-sm-3 control-label text-left">First Name</label>

        <div class="col-sm-9">
          <input
            type="text"
            class="form-control required"
            id="firstName"
            name="firstName"
            maxLength="50"
            #firstName="ngModel"
            placeholder="First Name"
            [(ngModel)]="currentUser.firstName"
            required />

          <p class="error-block" *ngIf="firstName.errors?.required">First Name is Required</p>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="checkField('lastName')"
        [ngClass]="{ 'has-error': lastName.invalid && (profileCompletenessForm.submitted || lastName.dirty) }">
        <label for="lastName" class="col-sm-3 control-label text-left">Last Name</label>

        <div class="col-sm-9">
          <input
            type="text"
            class="form-control required"
            id="lastName"
            placeholder="Last Name"
            #lastName="ngModel"
            required
            name="lastName"
            [(ngModel)]="currentUser.lastName"
            maxLength="50" />

          <p class="error-block" *ngIf="lastName.errors?.required">Last Name is Required</p>
        </div>
      </div>

      <div class="standardin-bottom row" *ngIf="checkField('picture')">
        <div class="col-sm-3 col-xs-12">
          <strong>Profile Image</strong>
        </div>
        <div class="col-sm-9 col-xs-12">
          <edit-user-avatar-new [user]="currentUser"></edit-user-avatar-new>
        </div>
      </div>

      <div class="form-group row" *ngIf="checkField('biography')">
        <label for="biography" class="col-sm-3 control-label text-left">About</label>

        <div class="col-sm-9">
          <textarea
            class="form-control"
            [(ngModel)]="currentUser.biography"
            id="biography"
            rows="6"
            name="biography"
            placeholder="About you..."></textarea>
        </div>
      </div>

      <div class="form-group row" *ngIf="checkField('subjectAreas') && subjectAreas.length">
        <div class="col-sm-3 control-label text-left"><strong>Area of Interest</strong></div>

        <div class="col-sm-9">
          <course-subject-areas
            [(selectedSubjectAreas)]="currentUser.subjectAreas"
            [subjectAreas]="subjectAreas"
            [formOptions]="profileCompletenessForm"
            [consistentView]="true"
            [areasOptional]="true"
            [showTitle]="false"></course-subject-areas>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-link"
      clickConfirmationTitle="Confirm"
      (clickConfirmation)="cancel()"
      clickConfirmationMessage="Discard unsaved changes?"
      clickConfirmationButtonOk="Discard"
      [clickConfirmationAllowed]="profileCompletenessForm.dirty">
      Cancel
    </button>

    <button
      type="submit"
      class="btn btn-primary"
      [ladda]="processing"
      data-spinner-color="#FFF"
      [disabled]="processing">
      Update
    </button>
  </div>
</form>

<div *ngIf="loading">
  <div class="load-spinner"></div>
</div>
