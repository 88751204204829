<div>
  <div class="flex">
    <div class="flex-row">
      <div class="flex-col mr-15" aria-hidden="true">
        <user-avatar [user]="comment.user" [size]="48" [isTransparent]="true"></user-avatar>
      </div>

      <div class="flex-col flex-col-grow">
        <div class="d-flex align-items-center-sm flex-direction-column-xs gap-5">
          <strong class="comment-title" *ngIf="comment.user.firstName && comment.user.lastName">
            <a
              *ngIf="isStateAvailable('main.user_network') && comment.user.hasSharedProfile"
              href=""
              uiSref="main.user_network"
              [uiParams]="{ userId: comment.user.id }"
              [id]="trackingName + '_profile_link'"
              >{{ comment.user.firstName }} {{ comment.user.lastName }}</a
            >
            <span *ngIf="!isStateAvailable('main.user_network') || !comment.user.hasSharedProfile"
              >{{ comment.user.firstName }} {{ comment.user.lastName }}</span
            >
          </strong>
          <strong class="comment-title" *ngIf="!(comment.user.firstName && comment.user.lastName)">
            <span>DELETED</span>
          </strong>
          <span class="dot-delimiter hidden-xs" aria-hidden="true"></span>
          <span>
            <span class="text-muted" [ngbTooltip]="tooltip" [placement]="['bottom', 'top']">{{
              formatDuration()
            }}</span>
            <span class="text-muted" *ngIf="isEdited()">
              <span class="dot-delimiter" aria-hidden="true"></span>
              edited
            </span>
            <span
              class="btn btn-primary btn-xs xs-margin-left"
              style="font-size: 73%; width: fit-content"
              *ngIf="comment.likes?.count">
              <span class="fa fa-thumbs-up" aria-hidden="true"></span>
              <span class="like-count">{{ comment.likes?.count || 0 }}<span class="sr-only"> likes. </span></span>
            </span>
          </span>
        </div>
        <!--Comment body-->
        <div class="comment-body mt-5 mb-5">
          <span [innerHtml]="comment.text | markdownToHtml"></span>
        </div>
        <div class="mb-5 mt-5" *ngIf="!selfView && allowLikes">
          <button
            type="button"
            class="btn btn-link no-padding-all no-text-decoration"
            [disabled]="likePromise"
            [id]="trackingName + '_like_button'"
            (click)="toggleLike()">
            <span *ngIf="!comment.likes?.liked">Like</span>
            <span *ngIf="comment.likes?.liked">Unlike</span>
          </button>
        </div>
      </div>

      <div class="flex-col flex-col-2x text-right">
        <div
          ngbDropdown
          *ngIf="selfView || isCommentEditable"
          [display]="'dynamic'"
          [placement]="['bottom-end', 'auto']">
          <a title="Actions" type="button" class="dot-menu-large action-icon" href="#" ngbDropdownToggle>
            <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
            <span class="sr-only">Open actions menu</span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem href="#" title="Edit" (click)="editComment()"> Edit </a>
            <button
              ngbDropdownItem
              type="button"
              class="button"
              title="Delete"
              (clickConfirmation)="deleteComment()"
              clickConfirmationTitle="Delete reply?"
              clickConfirmationMessage="This will delete your reply and this is not reversible."
              clickConfirmationButtonOk="Delete reply"
              [id]="trackingName + '_delete_button_' + comment.id">
              <span class="text-danger">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltip>
  <date-time [date]="comment.date"></date-time>
</ng-template>
