import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IValidationResult } from 'modules/password/models/password-policies.model';
import { PasswordPoliciesService } from 'modules/password/services/password-policies.service';
import { IUser } from 'modules/user/models/user.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'password-policies',
  templateUrl: './password-policies.component.html',
})
export class PasswordPoliciesComponent implements OnInit, OnChanges {
  @Input() user: Partial<IUser>;
  @Input() showOnlyFailed = false;
  @Input() newPasswordRules = false;
  @Input() rules: any[] = [];
  @Input() validatedRules: IValidationResult;

  @Output() rulesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  rulesStatus = {
    failedRules: [],
    completedRules: [],
  };

  loading = false;
  filteredRules = [];
  filteredCustomRules = [];
  private eventScheduled = new Subject<void>();

  constructor(private passwordPoliciesService: PasswordPoliciesService) {}

  ngOnInit(): void {
    this.loadPolicies();

    this.eventScheduled.pipe(debounceTime(1000)).subscribe(() => {
      this.loadPolicies();
    });
  }

  // Emit updated rules whenever there is a change
  updateRules() {
    this.rulesChange.emit(this.rules); // Notify parent component of the updated rules
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && !changes['user'].firstChange) {
      // Watch user property changes
      this.eventScheduled.next();
    }

    if (changes['validatedRules'] && this.rules) {
      // Watch user property changes
      this.populateRulesStatus();
    }
  }

  private loadPolicies(): void {
    this.passwordPoliciesService.getCustomerRules(true).subscribe((rules) => {
      if (this.newPasswordRules) {
        this.rules = rules.filter((rule: any) => rule.rule.coversNewPasswords);
      } else {
        this.rules = rules;
      }

      this.filteredRules = this.rules.filter((rule) => {
        return (
          !rule.rule.customErrorValidation && ((this.showOnlyFailed && rule.isValid === false) || !this.showOnlyFailed)
        );
      });
      this.filteredCustomRules = this.rules.filter((rule) => rule.rule.customErrorValidation);

      this.updateRules();
      this.populateRulesStatus();
    });
  }

  private clearStatus(): void {
    this.rules.forEach((rule: any) => {
      rule.isValid = undefined;
    });
  }

  private populateRulesStatus(): void {
    if (this.rules && this.validatedRules) {
      this.clearStatus();
      const userRulesDict = this.rules.reduce((acc: any, rule: any) => {
        acc[rule.rule.internalName] = rule;

        return acc;
      }, {});

      if (this.validatedRules) {
        this.validatedRules.completedRules.forEach((rule: any) => {
          if (userRulesDict[rule]) {
            userRulesDict[rule].isValid = true;
          }
        });
        this.validatedRules.failedRules.forEach((rule: any) => {
          if (userRulesDict[rule]) {
            userRulesDict[rule].isValid = false;
          }
        });
      }
    }
  }
}
