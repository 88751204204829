import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPasswordService } from 'modules/password/services/user-password.service';
import { IUser } from 'modules/user/models/user.model';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';

export interface IResolveProvidePasswordModal {
  user: IUser;
}

@Component({
  standalone: false,
  selector: 'provide-password-modal',
  templateUrl: './provide-password-dialog.component.html',
})
export class ProvidePasswordModalComponent implements OnInit {
  @Input() resolve!: IResolveProvidePasswordModal;

  user: IUser;
  userDraft: IUser;
  error: string | null = null;
  passwordConfirmationValue: string;
  submitted = false;
  changePasswordSubscriber: Unsubscribable;

  constructor(
    private userPasswordService: UserPasswordService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.userDraft = { ...this.user };
    delete this.user.password;
    delete this.user.oldPassword;
  }

  submitPassword(form) {
    this.submitted = true;

    if (form.valid) {
      const password = this.userDraft.password;

      if (this.userDraft.id) {
        this.changePasswordSubscriber = this.userPasswordService
          .changePassword(this.userDraft.id, password, this.userDraft.loginName, true)
          .pipe(
            tap(() => this.activeModal.close()),
            catchError((reason: HttpErrorResponse) => {
              // if (reason.status === 403 && reason.data && reason.data.code === 'PASSWORD_RULES_FAILED') {
              //   this.rootScopeService.broadcast('event:passwordRulesStatusUpdated', reason.data.context);
              // }

              this.error = reason.error;

              return EMPTY;
            }),
            finalize(() => {
              if (this.changePasswordSubscriber) {
                this.changePasswordSubscriber.unsubscribe();
                delete this.changePasswordSubscriber;
              }
            }),
          )
          .subscribe();
      } else {
        this.resolve.user.password = password;
        this.activeModal.close();
      }
    }
  }

  cancelModal(): void {
    this.activeModal.dismiss();
  }
}
