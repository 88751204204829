<form #emailVerificationRequiredForm="ngForm" style="line-height: 1.5">
  <!-- Confirmation Step -->
  <div *ngIf="step === 'confirmation'">
    <h3 class="mb-15 mt-0">Verify your email address</h3>

    <p class="mb-15">
      Your {{ currentSiteSetting.siteShortName }} account cannot be used until your email address has been verified.
      <a href="#" (click)="showVerificationImportance()" title="Important information"
        >Why email verification is important</a
      >.
    </p>

    <div class="mb-15">
      <div>Email to verify:</div>
      <div>
        <strong>{{ user.email }}</strong>
      </div>
    </div>

    <p class="mb-15">
      <span
        >If this email is no longer valid, you can
        <a href="#" (click)="nextStep('changeEmail')" title="Change my email">change it</a>.
      </span>
    </p>

    <button
      type="button"
      class="btn btn-lg btn-primary btn-block mb-15"
      [disabled]="processing"
      (click)="sendVerificationEmail('summary')"
      title="Verify">
      Verify
    </button>

    <div>
      <span
        >If you do not want or cannot verify your address,
        <a title="Contact support" [href]="contactsLink">contact support</a> or
        <a title="Log out" [href]="logoutLink">log out</a>.
      </span>
    </div>
  </div>

  <!-- Duplicate Confirmation Step -->
  <div *ngIf="step === 'duplicateConfirmation'">
    <h3 class="mb-15 mt-0">Verify your email address</h3>

    <div class="mb-15">
      <div>
        {{ currentSiteSetting.siteShortName }} will use this account to contact you and notify about your learning
        activity.
      </div>
      <div>
        <strong>{{ user.email }}</strong> will be associated with <strong>"{{ user.loginName }}"</strong> user account.
      </div>
    </div>

    <p class="mb-0">Please confirm the following to proceed:</p>

    <div class="row">
      <div class="col-sm-12">
        <div class="mt-5 mb-5">
          <label class="font-normal">
            <input name="myAccountEmail" type="checkbox" [(ngModel)]="myAccountEmail" autofocus />
            I confirm that <strong>{{ user.email }}</strong> email address exists and I have sole access to it
          </label>
        </div>
        <div class="mt-5 mb-5">
          <label class="font-normal">
            <input name="otherAccountsNotAvailable" type="checkbox" [(ngModel)]="otherAccountsNotAvailable" />
            I understand that other accounts that use the same email address will not be able to use
            <strong>{{ user.email }}</strong> email address
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <button
          type="button"
          class="btn btn-outline btn-lg btn-block mb-15"
          [disabled]="processing"
          (click)="prevStep()"
          title="Back">
          Back
        </button>
      </div>
      <div class="col-sm-6 col-xs-12">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block mb-15"
          [disabled]="processing || !myAccountEmail || !otherAccountsNotAvailable"
          (click)="sendVerificationEmail('summary')"
          title="Next">
          Next
        </button>
      </div>
    </div>
    <div>
      <span
        >If you do not want or cannot verify your address,
        <a title="Contact support" [href]="contactsLink">contact support</a> or
        <a title="Log out" [href]="logoutLink">log out</a>.
      </span>
    </div>
  </div>

  <!-- Other Steps -->
  <!-- DuplicatesWithVerified and DuplicatesNotVerified Steps -->
  <div *ngIf="step === 'duplicatesWithVerified' || step === 'duplicatesNotVerified'">
    <h3 class="mb-15 mt-0">Verify your email address</h3>

    <p class="alert alert-danger mb-15">
      This email address is used for multiple accounts.
      {{ currentSiteSetting.siteShortName }} no longer allows multiple user accounts with the same email address.
    </p>

    <p class="mb-15">
      Your {{ currentSiteSetting.siteShortName }} account cannot be used until your email address has been verified.
      <a href="#" (click)="showVerificationImportance()" title="Important information">
        Why email verification is important </a
      >.
    </p>

    <div class="mb-15">
      <div>Email to verify:</div>
      <div>
        <strong>{{ user.email }}</strong>
      </div>
    </div>

    <div class="row">
      <strong class="col-sm-12 col-xs-12" style="margin-bottom: -5px">Available options</strong>
      <div class="col-xs-12">
        <div class="radio" *ngIf="step === 'duplicatesNotVerified'">
          <label>
            <input type="radio" name="duplicateRadios" [(ngModel)]="duplicateOptions" [value]="1" autofocus />
            Use the email address with <strong>{{ user.loginName }}</strong> user account
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="duplicateRadios" [(ngModel)]="duplicateOptions" [value]="2" />
            Change email address for <strong>{{ user.loginName }}</strong> user account
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="duplicateRadios" [(ngModel)]="duplicateOptions" [value]="3" />
            Login with <strong>another account</strong> that uses the email address
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="duplicateRadios" [(ngModel)]="duplicateOptions" [value]="4" />
            Recover the account
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <button
          type="button"
          class="btn btn-lg btn-primary btn-block mb-15"
          (click)="duplicateContinue()"
          [disabled]="processing || !duplicateOptions"
          title="Continue">
          Continue
        </button>
      </div>
    </div>

    <div>
      <span>
        If you do not want or cannot verify your address,
        <a title="Contact support" [href]="contactsLink">contact support</a> or
        <a title="Log out" [href]="logoutLink">log out.</a>
      </span>
    </div>
  </div>

  <!-- Change Email Step -->
  <div *ngIf="step === 'changeEmail'">
    <h3 class="mb-15 mt-0">Change your email address</h3>

    <div *ngIf="!verificationInfo.uniqEmail && !verificationInfo.selfCreated" class="alert alert-danger mb-15">
      This user account was created by an administrator. Please provide your email address.
    </div>

    <div
      class="form-group mb-15"
      [ngClass]="{ 'has-error': (email.invalid || uniqueEmailError) && (submitted || email.dirty) }">
      <label for="email" class="control-label">New email address</label>

      <div class="row">
        <div class="col-sm-12">
          <input
            type="email"
            class="form-control required"
            id="email"
            name="email"
            email
            [(ngModel)]="userEmailDraft"
            #email="ngModel"
            placeholder="Email"
            required
            autoFocus
            maxlength="255" />
          <p class="help-block no-margin-bottom" *ngIf="email.invalid && (email.dirty || submitted)">
            <span *ngIf="email?.errors?.required">Email is required.</span>
            <span *ngIf="email.errors?.email">Email is invalid.</span>
          </p>

          <div *ngIf="uniqueEmailError || (userEmailDraft && userEmailDraft === user.email && !updating)">
            <span class="text-danger">
              User account with this email already exists. Choose a different email address.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="verificationInfo.uniqEmail || verificationInfo.selfCreated" class="col-sm-6 col-xs-12">
        <button
          type="button"
          class="btn btn-lg btn-outline btn-block mb-15"
          [disabled]="processing"
          (click)="prevStep()"
          title="Back">
          Back
        </button>
      </div>
      <div
        [ngClass]="{
          'col-sm-12 col-xs-12': !verificationInfo.uniqEmail && !verificationInfo.selfCreated,
          'col-sm-6 col-xs-12': verificationInfo.uniqEmail || verificationInfo.selfCreated,
        }">
        <button
          type="button"
          class="btn btn-lg btn-primary btn-block mb-15"
          [disabled]="processing"
          (click)="updateEmail()"
          title="Change and verify email">
          Change and verify
        </button>
      </div>
    </div>

    <div>
      <span
        >If you do not want or cannot verify your address,
        <a title="Contact support" [href]="contactsLink">contact support</a> or
        <a title="Log out" [href]="logoutLink">log out</a>.
      </span>
    </div>
  </div>

  <!-- Summary Step -->
  <div *ngIf="step === 'summary'">
    <h3 class="mb-15 mt-0">Verification email sent</h3>

    <p class="mb-15">
      To start using {{ currentSiteSetting.siteShortName }}, confirm your email address with the email we sent to:
    </p>
    <p class="mb-15">
      <strong>{{ user.email }}</strong>
    </p>
    <p class="mb-15">
      If you don't receive this email after 20 minutes, please check your junk mail folder or
      <a href="#" (click)="reInitVerification()" title="Request another email">request another email</a>.
    </p>

    <div>
      <span
        >If you do not want or cannot verify your address,
        <a title="Contact support" [href]="contactsLink">contact support</a> or
        <a title="Log out" [href]="logoutLink">log out</a>.
      </span>
    </div>
  </div>
</form>
