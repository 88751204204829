import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';
import { EditThreadModalComponent } from 'modules/network/edit/modal/edit-thread-modal.component';
import { EditThreadReplyModalComponent } from 'modules/network/edit/modal/edit-thread-reply-modal.component';
import { NetworkService } from 'modules/network/services/network.service';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';
import { UserNetworkCommentsService } from 'modules/network/services/user-network-comments.service';
import { NetworkActivityCommentComponent } from 'modules/network/view/network-activity-comment.component';
import { NetworkActivityComponent } from 'modules/network/view/network-activity.component';
import { UserModule } from 'modules/user/user.module';
import { SubjectAreasModule } from '../subject-areas/subject-areas.module';
import { EditUserModule } from '../user/edit/edit-user.module';
import { NetworkProfileCompletenessComponent } from './components/network-profile-completeness.component';
import { NetworkSubscriptionsComponent } from './components/network-subscriptions.component';
import { ComponentProfileModalComponent } from './modal/complete-profile-dialog.component';
import { NetworkRotatorService } from './services/network-rotator.service';
import { NetworkSubscriptionService } from './services/network-subscription.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CourseTilesModule,
    LmsComponentsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    UserModule,
    UIRouterUpgradeModule.forChild(),
    RoundProgressModule,
    SubjectAreasModule,
    EditUserModule,
  ],
  declarations: [
    EditThreadModalComponent,
    NetworkActivityComponent,
    EditThreadReplyModalComponent,
    NetworkActivityCommentComponent,
    NetworkProfileCompletenessComponent,
    NetworkSubscriptionsComponent,
    ComponentProfileModalComponent,
  ],
  exports: [
    NetworkActivityComponent,
    NetworkActivityCommentComponent,
    NetworkProfileCompletenessComponent,
    NetworkSubscriptionsComponent,
  ],
  providers: [
    NetworkService,
    UserNetworkActivityService,
    UserNetworkCommentsService,
    { provide: 'networkServiceConfig', useValue: { take: 10 } },
    { provide: 'networkConst', useValue: { SEARCH_REQUEST_ITEM_COUNT: 10 } },
    {
      provide: 'networkProfileCompletenessFields',
      useValue: ['firstName', 'lastName', 'picture', 'biography', 'subjectAreas'],
    },

    NetworkSubscriptionService,
    NetworkRotatorService,
  ],
})
export class NetworkModule {}
