import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { NavigationMenuComponent } from 'modules/navigation/components/navigation-menu.component';
import { NavigationTilesComponent } from 'modules/navigation/components/navigation-tiles.component';
import { MenuService } from 'modules/navigation/services/menu.service';

@NgModule({
  imports: [CommonModule, UIRouterModule, NgbDropdownToggle, NgbDropdown, NgbDropdownItem, NgbDropdownMenu],
  declarations: [NavigationTilesComponent, NavigationMenuComponent],
  exports: [NavigationTilesComponent, NavigationMenuComponent],
  providers: [MenuService],
})
export class NavigationModule {}
