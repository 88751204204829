import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import { HttpRequestsCounterInterceptor } from 'core/http-interceptors/http-requests-counter-interceptor';
import { NavigationService } from 'core/navigation';
import { LocationHistoryService } from 'core/navigation/location-history.service.ajs-upgraded-provider';
import { SecurityService } from 'core/services';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { asyncScheduler, observeOn, takeWhile } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'login-form',
  templateUrl: './login-form.component.html', // Adjust to your template path
})
export class LoginFormComponent implements OnInit {
  static readonly selector = 'loginForm';
  @Input() signin: string;
  @Input() email: string;
  @Input() next: string;
  @Input() hideBecomeCourseProvider = false;
  @Input() currentSiteSetting: ISiteSettings;

  affiliateShotName = 'TRAIN';
  currentDate: Date = new Date();
  integrationLoginPath: string | null = null;
  signInWording = this.globalConfig.signInWording;
  settings = this.globalConfig.settings;
  targetPath: string | null = null;
  loading = false;
  initialized = false;

  constructor(
    private requestInterceptor: HttpRequestsCounterInterceptor,
    private stateService: StateService,
    private globalConfig: GlobalConfig,
    public securityService: SecurityService,
    private locationHistory: LocationHistoryService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    // Fetch site settings asynchronously and ensure AngularJS knows about the update
    try {
      // this.currentSiteSetting = await this.siteSettingsService.getCurrent();
      this.integrationLoginPath = this.currentSiteSetting.integrationLoginPath;
      this.affiliateShotName = this.currentSiteSetting.siteShortName;
      // this.$scope.$applyAsync(); // Trigger AngularJS digest cycle
    } catch (error) {
      console.error('Error fetching site settings:', error);
    }

    this.watchPendingRequests();

    // Save the last path before login refresh
    if (this.locationHistory.getLength() > 0) {
      const state = this.locationHistory.pop();

      this.navigationService.setTargetState(state);
    }

    this.targetPath = this.next || this.navigationService.getRelativeTargetPath() || this.globalConfig.base;
    this.targetPath = this.targetPath ? encodeURIComponent(this.targetPath) : null;
  }

  isStateAvailable(): boolean {
    return this.securityService.isStateAvailable('prompt.userRegistration');
  }

  showCorporateLogin(): void {
    let params = '';

    if (this.targetPath) {
      params = `target_url=${this.targetPath}`;
    }

    const url = params
      ? [
          this.currentSiteSetting.integrationLoginPath,
          this.currentSiteSetting.integrationLoginPath.indexOf('?') === -1 ? '?' : '&',
          params,
        ].join('')
      : this.currentSiteSetting.integrationLoginPath;

    window.location.href = url;
  }

  createAccount(becomeCourseProvider?): void {
    if (becomeCourseProvider) {
      this.stateService.go('prompt.userRegistration', { stepCourseProvider: true });
    } else {
      this.stateService.go('prompt.userRegistration');
    }
  }

  private watchPendingRequests() {
    return this.requestInterceptor.activeRequestsStatus.pipe(
      observeOn(asyncScheduler),
      tap((isActive) => {
        this.loading = isActive;
      }),
      takeWhile((isActive) => isActive),
    );
  }
}
