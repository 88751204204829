<div class="modal-header">
  <button [disabled]="savePromise" type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title"><span *ngIf="!activity.id">Start</span><span *ngIf="activity.id">Edit</span> thread</h4>
</div>

<div class="modal-body overflow-auto edit-post-modal">
  <form #threadForm="ngForm">
    <label for="threadDraft" class="sr-only">Thread text</label>
    <textarea
      class="form-control no-margin-bottom no-margin-top required"
      name="threadDraft"
      rows="4"
      placeholder="Something goes here"
      id="threadDraft"
      ngbAutofocus
      noWhiteSpace
      [(ngModel)]="activity.text"></textarea>
  </form>
</div>

<div class="modal-footer">
  <fieldset class="no-margin-bottom">
    <button type="button" title="Cancel" class="btn btn-warning" (click)="cancel()">Cancel</button>

    <button
      type="button"
      [disabled]="!activity.text?.trim().length || savePromise"
      [ladda]="savePromise"
      [title]="activity.id ? 'Edit thread' : 'Start thread'"
      class="btn btn-success"
      (click)="submit()">
      {{ activity.id ? 'Edit thread' : 'Start thread' }}
    </button>
  </fieldset>
</div>
